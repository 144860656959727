import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/en/Layout"
import Header from "../../components/en/Header"
import WaltzRoll from "../../components/en/WaltzRoll"
import Footer from "../../components/en/Footer"

class WaltzRollPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charset="utf-8" />
          <title>WALTZ | Waltz Roll</title>
          <meta
            name="description"
            content="Rolat nikada nije bio tako ukusan kao Waltz roll okrugli sveži užitak, umotan u okruglu čaroliju kojom podsjećamo najmilije na ljubav i pažnju."
          />
          <meta name="image" content="https://waltz.rs/waltz_roll_seo.jpg" />
          <meta itemprop="name" content="WALTZ | Waltz Roll" />
          <meta
            itemprop="description"
            content="Rolat nikada nije bio tako ukusan kao Waltz roll okrugli sveži užitak, umotan u okruglu čaroliju kojom podsjećamo najmilije na ljubav i pažnju."
          />
          <meta
            itemprop="image"
            content="https://waltz.rs/waltz_roll_seo.jpg"
          />
          <meta name="og:title" content="WALTZ | Waltz Roll" />
          <meta
            name="og:description"
            content="Rolat nikada nije bio tako ukusan kao Waltz roll okrugli sveži užitak, umotan u okruglu čaroliju kojom podsjećamo najmilije na ljubav i pažnju."
          />
          <meta name="og:image" content="https://waltz.rs/waltz_roll_seo.jpg" />
          <meta name="og:url" content="https://waltz.rs/waltz-roll" />
          <meta name="og:site_name" content="WALTZ | Waltz Roll" />
          <meta name="og:locale" content="sr" />
          <meta name="og:type" content="website" />
        </Helmet>
        <Header />
        <WaltzRoll />
        <Footer />
      </Layout>
    )
  }
}
export default WaltzRollPage
